import React, { useState, useEffect } from "react";
import "./App.css";
import "./css/storeMenu.css";
import "./css/topNavbar.css";
import "./css/home.css";
import loadable from "@loadable/component";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// Load axios for api call
import axios from "axios";
import Login from "./modules/Auth/Login";
import Register from "./modules/Auth/Register";
import MainHomePage from "./modules/home/MainHomePage";
import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewPassword from "./modules/NewPassword/NewPassword";

// load required components
const TopNavbar = loadable(() => import("./modules/home/navigation/topNavbar"));
const MenuListHome = loadable(
  () => import("./modules/storeMenu/storeMenuHome/menuHome")
);
const HomeScreenStoreList = loadable(
  () => import("./modules/home/storesDetials/storeList")
);

// load material components
const Grid = loadable(() => import("@mui/material/Grid"));

const App = () => {
  const [selectedStoreId, setSelectedStoreId] = useState(1);
  const [selectedTabValue, setSelectedTabValue] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const store = queryParams.get("storeID");

  // drawer states start here
  const [showHideDrawer, setShowHideDrawer] = useState(false);
  const [drawerCount, setDrawerCount] = useState(0);

  // stores states
  const [storeList, setStoreList] = useState([]);
  const [loading, setLoading] = useState(false);
  // flavours list
  const [flavorList, setFlavorList] = useState<any[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await setLoading(true);
    // await getFlavors();
    setLoading(false);
  };

  // api call to fetch stores lis

  // const getFlavors = async () => {
  //   await axios.get(`https://www.budbox.xyz/menu_data_llc/10/`)
  //     .then((response: any) => {
  //       setFlavorList(response.data.data);
  //       setErrorMsg('');
  //       return true;
  //     }).catch((err: any) => {
  //       setFlavorList([]);
  //       setErrorMsg('No Data Found');
  //       return true;
  //     });
  // };

  // open/close shopping cart drawer
  const toggleCartDrawer = () => {
    setShowHideDrawer(!showHideDrawer);
  };

  return (
    <Grid sx={{ background: "#000" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainHomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password-reset/123" element={<NewPassword />} />
          <Route path="/password-resets" element={<NewPassword />} />
          <Route
            path="/menu"
            element={
              <>
                <TopNavbar
                  updatedSelectedStoreId={(id: number) =>
                    setSelectedStoreId(id)
                  }
                  updatedSelectedTabValue={(id: number) =>
                    setSelectedTabValue(id)
                  }
                  cartItemCount={drawerCount}
                  toggleCartDrawer={toggleCartDrawer}
                  storeList={storeList}
                />
                <MenuListHome
                  selectedStoreId={selectedStoreId}
                  showHideDrawer={showHideDrawer}
                  toggleCartDrawer={toggleCartDrawer}
                  updatedCartItemCount={(count: number) =>
                    setDrawerCount(count)
                  }
                />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
    </Grid>
  );
};

export default App;
