import React from "react";
import { UserProvider } from "./UserContext";

const AppProviders: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <UserProvider>{children}</UserProvider>;
};

export default AppProviders;
