import { useGoogleLogin } from "@react-oauth/google";
import "./login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const CustomGoogleRegisterBtn = () => {
  const navigate = useNavigate();
  const { setUserData } = useUser();
  const [loading, setLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: async (res) => {
      const accessToken = res.access_token;

      try {
        const userInfoResponse = await fetch(
          "https://www.googleapis.com/oauth2/v1/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (userInfoResponse.ok) {
          setLoading(true);
          const userInfo = await userInfoResponse.json();
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/customerlogins/`,
              {
                customer: {
                  name: userInfo.name,
                  email: userInfo.email,
                },
                username: userInfo.email,
                email: userInfo.email,
                password: userInfo.id,
                first_name: userInfo.given_name,
                last_name: userInfo.family_name,
              },

              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
                },
              }
            )
            .then(() => {
              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/api/check-customerlogin/`,
                  {
                    email: userInfo.email,
                    password: userInfo.password,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
                    },
                  }
                )
                .then((response: any) => {
                  setUserData(response.data);
                  setLoading(false);
                  toast.success("Login Successful");
                  navigate("/");
                })
                .catch((err: any) => {
                  setLoading(false);
                  toast.error("Something went wrong!");
                  return;
                });
            })
            .catch((err: any) => {
              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/api/check-customerlogin/`,
                  {
                    email: userInfo.email,
                    password: userInfo.password,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
                    },
                  }
                )
                .then((response: any) => {
                  setUserData(response.data);
                  setLoading(false);
                  toast.success("Login Successful");
                  navigate("/");
                })
                .catch((err: any) => {
                  console.log(err);
                  toast.error("Something went wrong!");
                  setLoading(false);
                  return;
                });
              setLoading(false);

              return;
            });
        } else {
          console.error(
            "Failed to fetch user information:",
            userInfoResponse.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    },
  });

  return (
    <button
      disabled={loading}
      type="button"
      className="custom-google-btn"
      onClick={() => login()}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <ClipLoader size={25} color="#DE0324" />
        </div>
      ) : (
        "Sign in with Google 🚀"
      )}
    </button>
  );
};

export default CustomGoogleRegisterBtn;
