import bigLogo from "../../images/big-logo.png";
import Login from "../../modules/Auth/Login";

const ContactForm = () => {
  return (
    <div>
      <section className="contact-form">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Login />
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="contact-block">
                <img src={bigLogo} alt="" />
                <ul>
                  <li>GET 10% off AND FREE PREROLL ON YOUR FIRST ORDER</li>
                  <li>GET TEXT MESSAGE UPDATES ON DEALS</li>
                  <li>1 POINT EARNED FOR EVERY $10 SPENT</li>
                  <li>REDEEM POINTS, SAVE MONEY</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d103210.55957837541!2d-115.21613594817484!3d36.06105457886067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c57a76423ced%3A0x98534c935aac7b95!2sParadise%2C%20NV%2C%20USA!5e0!3m2!1sen!2sin!4v1678858688538!5m2!1sen!2sin"
          style={{ border: 0 }}
          title="homepageContact"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactForm;
