import "./login.css";
import CustomGoogleLoginBtn from "./CustomGoogleLoginBtn";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Navbar from "../../commonComponents/Navbar";
import CreatePasswordModal from "../../modal/createPassword/createPasswordModal";
import { toast } from "react-toastify";
import { useUser } from "../../context/UserContext";
import ForgotPassword from "../../modal/forgotPassword/ForgotPassword";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const { setUserData } = useUser();

  const [showCreatePasswordModal, setShowCreatePasswordModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const location = useLocation();

  const submitLogin = async (e: any) => {
    e.preventDefault();

    const loginData = {
      username: data.email,
      password: data.password,
    };

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/customer-normal-login/`,
        loginData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response: any) => {
        setUserData(response.data);
        toast.success("Login Successful");
        navigate("/");
      })
      .catch((err: any) => {
        if (err?.response.data.is_null) {
          setShowCreatePasswordModal(true);
        } else {
          setShowCreatePasswordModal(false);
          toast.error("Invalid Username or Password");
          return;
        }
      });
  };

  return (
    <>
      {location.pathname !== "/" && (
        <div style={{ background: "white" }}>
          <Navbar />
        </div>
      )}
      <div className="login-main">
        <form onSubmit={submitLogin} className="login-container">
          <div className="login-box">
            <div className="login-top-text">
              <h1>Sign In to the Store</h1>
              <p>
                New Here?{" "}
                <Link className="link" to="/register">
                  <span onClick={() => window.scrollTo(0, 0)}>
                    Create an Account -&gt;
                  </span>
                </Link>
              </p>
            </div>
            <div>
              <CustomGoogleLoginBtn />
            </div>
            <div className="or-container">
              <div className="line"></div>
              <h4>or</h4>
              <div className="line"></div>
            </div>
            <div className="login-inputs">
              <input
                type="text"
                name="email"
                placeholder="Username / Email Address"
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                autoComplete="off"
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
                autoComplete="off"
                required
              />
            </div>

            <div
              onClick={() => setShowForgotPasswordModal(true)}
              className="login-forgot-password"
            >
              Forgot Your Password?
            </div>

            <div className="login-btn">
              <button>Sign In &gt;&gt;&gt;</button>
            </div>
            <div className="login-TC">
              By signing in you accent our Privacy Policy, Terms & Conditions.
              <br />
              Protected by reCAPTCHA. Google Privacy Policy & Terms apply,
            </div>
          </div>
        </form>
      </div>
      {showCreatePasswordModal && (
        <CreatePasswordModal
          userName={data.email}
          setShowCreatePasswordModal={setShowCreatePasswordModal}
        />
      )}
      {showForgotPasswordModal && (
        <ForgotPassword
          setShowForgotPasswordModal={setShowForgotPasswordModal}
        />
      )}
    </>
  );
};

export default Login;
