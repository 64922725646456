import whiteLogo from "../images/white-logo.svg";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="foot-logo">
                <img src={whiteLogo} alt="" />
                <ul>
                  <li>
                    12345 Some Address Street SW <br />
                    Washington DC, DC 12345-123
                  </li>
                  <li>
                    <a href="#">202-202-2002</a>
                  </li>
                  <li>
                    <a href="#">order@vibez.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5 col-sm-12">
              <div className="quicklinks">
                <h3>Dispensary Hours</h3>

                <ul className="hours-box">
                  <li>
                    Monday - Friday<span>8:00 AM - 8:00 PM</span>
                  </li>
                  <li>
                    Saturday <span>8:00 AM - 8:00 PM </span>
                  </li>
                  <li>
                    Sunday <span>8:00 AM - 8:00 PM </span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="#">Vibes Rewards {">"} </a>
                  </li>
                  <li>
                    <a href="#">Menu {">"} </a>
                  </li>
                  <li>
                    <a href="#">Order Online {">"} </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="socialbx">
                <h3>Follow Us!</h3>
                <ul>
                  <li>
                    <a href="#"></a>
                  </li>
                  <li>
                    <a href="#"></a>
                  </li>
                  <li>
                    <a href="#"></a>
                  </li>
                  <li>
                    <a href="#"></a>
                  </li>
                </ul>

                <h6>STAFF PORTAL</h6>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
