import "../../css/style.css";
import "../../css/bootstrap.min.css";
import "../../css/apexcharts.css";
import "../../css/responsive.css";
import Hero from "../../components/mainHomeComponents/Hero";
import About from "../../components/mainHomeComponents/About";
import Shop from "../../components/mainHomeComponents/Shop";
import ContactForm from "../../components/mainHomeComponents/ContactForm";
import Navbar from "../../commonComponents/Navbar";
import Footer from "../../commonComponents/Footer";

const MainHomePage = () => {
  return (
    <div style={{ background: "#ffffff", overflow: "hidden" }}>
      <Navbar />
      <Hero />
      <About />
      <Shop />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default MainHomePage;
