import { useState } from "react";
import Footer from "../../commonComponents/Footer";
import Navbar from "../../commonComponents/Navbar";
import "./NewPassword.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const NewPassword = (props: any) => {
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  const navgiate = useNavigate();

  const { token } = useParams();

  const handlePasswordSubmit = async (e: any) => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      toast.error("Password doest match");
      return;
    }

    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/password-reset/confirm/${token}/`,
        { new_password: data.password },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response: any) => {
        toast.success("Password Changed Sucessfully!");
        setLoading(false);
        navgiate("/login");
      })
      .catch((err: any) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
        return;
      });
  };

  return (
    <>
      <div style={{ background: "white" }}>
        <Navbar />
      </div>
      <div style={{ height: "100vh", background: "white" }}>
        <div className="new-password-modal">
          <div className="new-password-modal-content">
            <form onSubmit={handlePasswordSubmit} className="new-password-form">
              <h1>Set a new password</h1>
              <span>Your password must contain at least 8 characters !</span>
              <p>Your password can’t be entirely numeric !</p>
              <div className="new-password-inputs">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={data.password}
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                  autoComplete="off"
                  required
                />
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={data.confirmPassword}
                  onChange={(e) =>
                    setData({ ...data, confirmPassword: e.target.value })
                  }
                  autoComplete="off"
                  required
                />
              </div>

              <div className="new-password-btn">
                <button disabled={loading} type="submit">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <ClipLoader size={25} color="#ffffff" />
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewPassword;
