import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.svg";
import { useUser } from "../context/UserContext";

const Navbar = () => {
  const location = useLocation();
  const { userData } = useUser();

  return (
    <div>
      <header>
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#NavbarToggle"
              aria-controls="NavbarToggle"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="NavbarToggle">
              <ul className="navbar-nav">
                {location.pathname !== "/login" &&
                  location.pathname !== "/register" && (
                    <li>
                      <Link className="nav-link" to="/menu">
                        MENU
                      </Link>
                    </li>
                  )}

                {userData.customer.id === 0 ? (
                  <>
                    <li>
                      <Link className="nav-link" to="/login">
                        LOGIN
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link" to="/register">
                        REGISTER
                      </Link>
                    </li>
                  </>
                ) : (
                  <li
                    onClick={() => window.location.reload()}
                    className="nav-link"
                  >
                    LOGOUT
                  </li>
                )}

                {/* <li>
                  <a className="nav-link" href="#">
                    BUDMAPS
                  </a>
                </li> */}
                {/* <li>
                  <a className="nav-link" href="#">
                    BLOG
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#">
                    BUDGRAM
                  </a>
                </li> */}
                {/* <li>
                  <a className="nav-link" href="#">
                    <i className="fas fa-id-card"></i> PROFILE
                  </a>
                </li> */}
                {/* <li>
                  <a className="nav-link" href="#">
                    <i className="fal fa-shopping-cart"></i>
                  </a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
