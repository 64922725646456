import { useState } from "react";
import "./CreatePasswordModal.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";

const CreatePasswordModal = ({
  setShowCreatePasswordModal,
  userName,
}: {
  setShowCreatePasswordModal: any;
  userName: any;
}) => {
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  const { setUserData } = useUser();

  const handlePasswordSubmit = async (e: any) => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      toast.error("Password doest match");
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/change-null-pw/`,
        { username: userName, password: data.password },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response: any) => {
        setUserData(response.data);
        toast.success("Login Successful");
        navigate("/");
      })
      .catch((err: any) => {
        if (err?.response.data.is_null) {
          setShowCreatePasswordModal(true);
        } else {
          setShowCreatePasswordModal(false);
          toast.error("Invalid Username or Password");
          return;
        }
      });
  };

  return (
    <div className="password-modal">
      <div className="password-modal-content">
        <span
          onClick={() => setShowCreatePasswordModal(false)}
          className="password-close"
        >
          &times;
        </span>
        <form onSubmit={handlePasswordSubmit} className="password-form">
          <h1>Create New Password</h1>
          <div className="password-inputs">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              autoComplete="off"
              required
            />
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={data.confirmPassword}
              onChange={(e) =>
                setData({ ...data, confirmPassword: e.target.value })
              }
              autoComplete="off"
              required
            />
          </div>

          <div className="password-btn">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePasswordModal;
