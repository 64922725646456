import React, { createContext, useContext, useState } from "react";

interface CustomerData {
  address: string | null;
  branch: string | null;
  contact_number: string | null;
  email: string;
  id: number;
  loyalty_points: number;
  name: string;
  tax_number: string | null;
}

interface UserContextType {
  userData: { customer: CustomerData };
  setUserData: React.Dispatch<React.SetStateAction<{ customer: CustomerData }>>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userData, setUserData] = useState<{
    customer: CustomerData;
  }>({
    customer: {
      address: null,
      branch: null,
      contact_number: null,
      email: "",
      id: 0,
      loyalty_points: 0,
      name: "",
      tax_number: null,
    },
  });

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
