import { useState } from "react";
import "./ForgotPassword.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const ForgotPassword = ({
  setShowForgotPasswordModal,
}: {
  setShowForgotPasswordModal: any;
}) => {
  const [data, setData] = useState({
    email: "",
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleForgotPassword = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/password-reset/`,
        { email: data.email },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response: any) => {
        toast.success("Password Rest Email Sent!");
        setLoading(false);
        setShowForgotPasswordModal(false);
        navigate("/login");
      })
      .catch((err: any) => {
        toast.error("Something Went Wrong!");
        setLoading(false);
        return;
      });
  };

  return (
    <div className="forgot-password-modal">
      <div className="forgot-password-modal-content">
        <span
          onClick={() => setShowForgotPasswordModal(false)}
          className="forgot-password-close"
        >
          &times;
        </span>
        <form onSubmit={handleForgotPassword} className="forgot-password-form">
          <h1>Forgot Password ?</h1>
          <p>Enter your email to reset your password.</p>
          <div className="forgot-password-inputs">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              autoComplete="off"
              required
            />
          </div>

          <div className="forgot-password-btn">
            <button disabled={loading} type="submit">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <ClipLoader size={25} color="#ffffff" />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
