import shopicon1 from "../../images/shop-icon1.svg";
import shopicon2 from "../../images/shop-icon2.svg";
import shopicon3 from "../../images/shop-icon3.svg";
import shopicon4 from "../../images/shop-icon4.svg";
import shopicon5 from "../../images/shop-icon5.svg";
import shopicon6 from "../../images/shop-icon6.svg";
import { Link } from "react-router-dom";

const Shop = () => {
  return (
    <div>
      <section className="shop-wrp">
        <div className="container">
          <div className="titlebar">
            <h2>GIFT SHOP</h2>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-4">
              <Link to="/menu">
                <a href="">
                  <div className="shop-box">
                    <div className="box-icon">
                      <img src={shopicon1} alt="" />
                    </div>
                    <h4>Flower</h4>
                  </div>
                </a>
              </Link>
            </div>
            <div className="col-md-2 col-sm-4">
              <Link to="/menu">
                <a href="#">
                  <div className="shop-box">
                    <div className="box-icon">
                      <img src={shopicon2} alt="" />
                    </div>
                    <h4>Vapes</h4>
                  </div>
                </a>
              </Link>
            </div>
            <div className="col-md-2 col-sm-4">
              <Link to="/menu">
                <a href="#">
                  <div className="shop-box">
                    <div className="box-icon">
                      <img src={shopicon3} alt="" />
                    </div>
                    <h4>Concentrates</h4>
                  </div>
                </a>
              </Link>
            </div>
            <div className="col-md-2 col-sm-4">
              <Link to="/menu">
                <a href="#">
                  <div className="shop-box">
                    <div className="box-icon">
                      <img src={shopicon4} alt="" />
                    </div>
                    <h4>Topicals</h4>
                  </div>
                </a>
              </Link>
            </div>
            <div className="col-md-2 col-sm-4">
              <Link to="/menu">
                <a href="#">
                  <div className="shop-box">
                    <div className="box-icon">
                      <img src={shopicon5} alt="" />
                    </div>
                    <h4>Edibles</h4>
                  </div>
                </a>
              </Link>
            </div>
            <div className="col-md-2 col-sm-4">
              <Link to="/menu">
                <a href="#">
                  <div className="shop-box">
                    <div className="box-icon">
                      <img src={shopicon6} alt="" />
                    </div>
                    <h4>Pre-Rolls</h4>
                  </div>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Shop;
