import React from "react";
import heroicon1 from "../../images/hero-icon1.svg";
import heroicon2 from "../../images/hero-icon2.svg";
import heroicon3 from "../../images/hero-icon3.svg";
import heroicon4 from "../../images/hero-icon4.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div>
      <section className="hero-wrp">
        <div className="container title-container">
          <div className="hero-title">
            <ul className="list-rating">
              <li>
                <i className="fas fa-star"></i>
              </li>
              <li>
                <i className="fas fa-star"></i>
              </li>
              <li>
                <i className="fas fa-star"></i>
              </li>
            </ul>
            <h1>
              WELCOME TO VIBEZ <span>WASHINTON DC</span>
            </h1>

            <Link className="btn-main" to="/menu">
              MENU / ORDER ONLINE
            </Link>
          </div>
        </div>

        <div className="hero-block">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="info-box">
                  <div className="box-icon">
                    <img src={heroicon1} alt="" />
                  </div>
                  <h3>Premium Products</h3>
                  <p>
                    We offer a variety of lab tested cannabis products to meet
                    your needs for any experience or price range. We only cary
                    the highest quality medicine.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="info-box">
                  <div className="box-icon">
                    <img src={heroicon2} alt="" />
                  </div>
                  <h3>Get Rewards</h3>
                  <p>
                    Get rewarded every time <br />
                    you make a donation! We have our point system set up so that
                    you get the best discounts and deals.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="info-box">
                  <div className="box-icon">
                    <img src={heroicon3} alt="" />
                  </div>
                  <h3>Daily Deals</h3>
                  <p>
                    Get rewarded every time you make a donation! We have our
                    point system set up so that you get the best discounts and
                    deals.
                  </p>
                </div>
              </div>
              {/* <div className="col-md-3 col-sm-12">
                <div className="info-box">
                  <div className="box-icon">
                    <img src={heroicon4} alt="" />
                  </div>
                  <h3>On-Demand Delivery</h3>
                  <p>
                    Order online and we will deliver to you in the Washington DC
                    area. There is no need to leave the comfort of your home.
                    Let us come to you!
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
