import "./login.css";
import CustomGoogleRegisterBtn from "./CustomGoogleRegisterBtn";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Navbar from "../../commonComponents/Navbar";
import { toast } from "react-toastify";

const Login = () => {
  const [data, setData] = useState({
    fname: "",
    lname: "",
    email: "",
    address: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const submitRegister = async (e: any) => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      toast.error("Password doest match");
      return;
    }

    const registerData = {
      customer: {
        name: data.fname + "" + data.lname,
        address: data.address,
        contact_number: data.phone,
        email: data.email,
      },
      username: data.email,
      email: data.email,
      password: data.password,
    };

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/customer-normal-register/`,
        registerData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      )
      .then((response: any) => {
        toast.success("Register Successful");
        navigate("/login");
      })
      .catch((err: any) => {
        if (err.response.data.username) {
          toast.error(err.response.data.username[0]);
        } else {
          toast.error("Something went wrong!");
        }
        return;
      });
  };

  return (
    <>
      <div style={{ background: "white" }}>
        <Navbar />
      </div>
      <div className="login-main">
        <form onSubmit={submitRegister} className="login-container">
          <div className="login-box">
            <div className="login-top-text">
              <h1>Create an Account</h1>
              <p>
                Already a member?{" "}
                <Link className="link" to="/login">
                  <span>Log In -&gt; -&gt;</span>
                </Link>
              </p>
            </div>
            <div>
              <CustomGoogleRegisterBtn />
            </div>
            <div className="or-container">
              <div className="line"></div>
              <h4>or</h4>
              <div className="line"></div>
            </div>
            <div className="login-inputs">
              <input
                type="text"
                name="fname"
                placeholder="First Name"
                value={data.fname}
                onChange={(e) => setData({ ...data, fname: e.target.value })}
                autoComplete="off"
                required
              />
              <input
                type="text"
                name="lname"
                placeholder="Last Name"
                value={data.lname}
                onChange={(e) => setData({ ...data, lname: e.target.value })}
                autoComplete="off"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                autoComplete="off"
                required
              />
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={data.address}
                onChange={(e) => setData({ ...data, address: e.target.value })}
                autoComplete="off"
                required
              />
              <input
                type="number"
                name="phone"
                placeholder="Mobile Number"
                value={data.phone}
                onChange={(e) => setData({ ...data, phone: e.target.value })}
                autoComplete="off"
                required
              />
              <input
                type="password"
                name="password"
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
                placeholder="Password"
                autoComplete="off"
                required
              />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={data.confirmPassword}
                onChange={(e) =>
                  setData({ ...data, confirmPassword: e.target.value })
                }
                autoComplete="off"
                required
              />
              {/* <input
              type="password"
              name="confirmpassword"
              placeholder="Confirm Password"
              value={data.confirmpassword}
              onChange={(e) =>
                setData({ ...data, confirmpassword: e.target.value })
              }
              autoComplete="off"
              required
            /> */}
            </div>

            <div className="login-btn">
              <button type="submit">Sign Up &gt;&gt;&gt;</button>
            </div>
            <div className="login-TC">
              By signing up you accent our Privacy Policy, Terms & Conditions.
              <br />
              Protected by reCAPTCHA. Google Privacy Policy & Terms apply,
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
