import React from "react";

const About = () => {
  return (
    <div>
      <section className="about-wrp">
        <div className="container">
          <div className="about-content">
            <h2>ABOUT US</h2>
            <p>
              At VIBEZ, you can find over 120 of the best indica, sativa, and
              hybrid strains. We strive to only offer the best and premium
              cannabis products such as CBD, cannabis concentrates, cannabis
              vapes, premium flower, and cannabis edibles. Stop by our 24/7
              cannabis dispensary to speak to our expert staff about medical or
              recreational cannabis.
            </p>
            <p>
              We are a compassionate and knowledgeable Initiative 71 compliant
              dispensary with the intent to provide our community with premium
              quality medicine. Our staff is knowledgeable, very professional &
              helpful. We are on a mission to enhance safe access to legal
              cannabis, educate people about cannabis as a tool for wellness,
              and drive smart cannabis policies.
            </p>
            <p>
              All Customers MUST have your original and valid DC Drivers
              License/DC ID/Out of State ID/Passport. Additional inquiries or
              comments, please feel free to call us.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
